import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";
import "./option_box.css"

const isNumeric = (num) => {
  return !isNaN(num);
};

const mySplit = function (text) {
  let char = "";

  for (let c of [...text]) {
    if (isNumeric(c) && c !== " ") {
      char = c;
      break;
    }
  }

  let arr = [];
  arr[0] = text.substring(0, text.indexOf(char));
  arr[1] = text.substring(text.indexOf(char));
  return arr;
};

function OptionBox({ options, setRespuestaId }) {
    const [xs, setXs] = useState(false);
    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

  const selectOption = (value, option) => {
      // console.log('option', option)
    setRespuestaId(value);
    let activeBox = document.querySelector(".activeOptionBox");
    if (activeBox) activeBox.classList.remove("activeOptionBox");
    option.classList.add("activeOptionBox");
      let anchordiv = document.querySelector(".submitArea");
      anchordiv.scrollIntoView({ behavior: 'smooth', block: `${xs ? 'end': 'center'}`});
  };

  let optionsArr = [];
  options.forEach((element, index) => {
    let wordArray = mySplit(element.text);
      optionsArr.push(
          <Col key={index}>

            <div className={
                            element.text.includes("año")
                                ? "optionBox yearsOptions"
                                : "optionBox percentOptions"
                          }
                          role="button"
                          tabIndex={index}

                          onClick={(e) => selectOption(element.value, e.currentTarget)}
            >
            <span className="first-part">{wordArray[0]}</span><span className="second-part">{wordArray[1]}</span>
            </div>
          </Col>
      )
  });

  return (
    <>
        <Row xs={2} md={4} className="g-2 g-lg-3 d-flex justify-content-center">
            {optionsArr}
        </Row>
    </>
  );
}

export default OptionBox;
