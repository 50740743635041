import * as React from 'react';
//import * as CSS from 'csstype';
export interface IStep {
  stepContent: () => JSX.Element;
  statusColor?: string;
  statusCircleSize?: number;
  onClickHandler?: (
    event?: React.MouseEvent<HTMLDivElement>,
  ) => void | undefined;
}

/*const buttonContainerStyles: CSS.Properties = {
  display: 'inline-flex',
  flexWrap: 'wrap',
  gap: '32px',
  cursor: 'pointer',
};*/

export const Step = ({
  stepContent,
  statusColor,
  statusCircleSize,
  onClickHandler,
  }: IStep): JSX.Element => {
  const circleStyles = {
    background: statusColor ?? 'white',
  };

 /* const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      onClickHandler?.();
    }
  };*/

  return (
    <div
      tabIndex={0}
      //onClick={onClickHandler}
     // onKeyDown={keyDownHandler}
      role="button"
      /*style={{...buttonContainerStyles}}*/
      className="separatorTop"
      >
      <div className="stepCircle-container" >
        <div className="stepCircle" style={circleStyles} />
      </div>
      <div className="d-table flex-fill">{stepContent()}</div>
    </div>
  );
};
