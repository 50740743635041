import React, {useEffect, useState} from "react";
import { StepperNav } from "./VerticalStep";
import "./vertical_steps.css";

const VerticalSteps = ({ setRespuestaId, options }) => {
  const mySplit = function (char, text) {
    let arr = [];
    arr[0] = text.substring(0, text.indexOf(char));
    arr[1] = text.substring(text.indexOf(char) + 1);
    return arr;
  };
  const [xs, setXs] = useState(false);

  useEffect(() => {
    const handleXs = () => {
      const isXs = window.innerWidth <= 768;
      setXs(isXs);
    };

    window.addEventListener('resize', handleXs);

    handleXs();

    return () => {
      window.removeEventListener("resize", handleXs);
    };
  }, []);

  const selectAnswer = (answer, target) => {
    // console.log('target',target)
    setRespuestaId(answer);
    let stepIndex =
      target.parentElement.parentElement.parentElement.dataset.indexNumber;
    let activeStep = document.querySelector(".activeStep");
    let activeQuestion = document.querySelector(".activeStepQuestion");

    if (activeStep) {
      activeStep.classList.remove("activeStep");
      activeQuestion.classList.remove("activeStepQuestion");
    }

    if (target.classList.contains("stepCircle")) {
      target.parentElement.nextElementSibling.classList.add("activeStep");
      target.parentElement.nextElementSibling.children[0].classList.add("activeStepQuestion");
    } else {
      target.parentElement.classList.add("activeStep");
      target.children[0].classList.add("activeStepQuestion");
    }

    let stepCircles = document.querySelectorAll(".stepCircle");
    if (stepCircles.length > 0) {
      stepCircles.forEach((element, index) => {
        if (index <= stepIndex) {
          //console.log(element, "again")
          element.style.backgroundColor = options[index].alt;
          element.style.border = "none";
          element.parentElement.style.zIndex = 2;
        } else {
          element.style.backgroundColor = "white";
          element.parentElement.style.zIndex = 0;
          element.style.border = "1px solid var(--light-grey-txt-color)";
        }
      });
    }

    let separators = document.querySelectorAll(".stepperSeparator");
    if (separators.length > 0) {
      separators.forEach((element, index) => {
        if (index < stepIndex) {
          element.classList.add("activeSep")
          element.parentElement.style.zIndex = 1;
        } else if(index == stepIndex){
          element.classList.remove("activeSep")
          element.style.zIndex = 1;
        }else{
          element.classList.remove("activeSep")
           element.parentElement.style.zIndex = 2;
        }
      });
    }

    let anchordiv = document.querySelector(".submitArea");
    anchordiv.scrollIntoView({ behavior: 'smooth', block: `${xs ? 'end': 'center'}`});
  };

  let steps = options.map((step, index) => {
    let wordArray = mySplit(".", step.text);
    return {
      stepContent: () => (
        <div
          className="customStepClass"
          onClick={(e) => {
            selectAnswer(step.value, e.currentTarget);
          }}
          role="button"
          tabIndex={index}
          /*style={{ fontSize: 14 }}*/
          key={index}
        >
          {
            <div className="stepQuestion" style={{ pointerEvents: "none" }}>
              <span>{wordArray[0]}.</span>
            </div>
          }
          {
            <span className="stepDescription" style={{ pointerEvents: "none" }}>
              {wordArray[1]}
            </span>
          }
        </div>
      ),
     /* stepStatusCircleSize: 45,*/
      stepStateColor: step.color,
      onClickHandler: (e) => {
        selectAnswer(step.value, e.target);
      },
      index: index,
    };
  });

  return <StepperNav steps={steps} />;
};

export default VerticalSteps;
